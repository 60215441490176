import Cookies from "js-cookie";
let domain = null;
if (location.hostname.indexOf(".mc.cmcloud.org") > -1) {
  domain = ".mc.cmcloud.org";
} else if (location.hostname.indexOf(".marshotspot.com") > -1) {
  domain = ".marshotspot.com";
} else if (location.hostname.indexOf('.polymericcloud.com') > -1) {
  domain = '.polymericcloud.com'
}

export function getCookies(TokenKey) {
  return Cookies.get(TokenKey);
}

export function setCookies(TokenKey, token) {
  // return Cookies.set(TokenKey, token);
  return Cookies.set(TokenKey, token, { domain: domain });
}

export function removeCookies(TokenKey) {
  return Cookies.remove(TokenKey, { domain: domain });
}
