<template>
  <div>
    <div class="kubestar-title">
      <svg-icon icon-class="control"></svg-icon>
      {{ $t("KubeStarControlPanel") }}
    </div>

    <div class="search-contaier">
      <div class="search-title">
        <i class="el-icon-s-home"></i>
        {{ $t("mainNavTitle") }}
      </div>

      <div class="search-input">
        <el-popover placement="bottom" trigger="manual" width="800" v-model="popoverVisible">
          <div class="search-popover-container" @mousedown.stop>
            <div v-loading="searchLoading">
              <el-tabs type="border-card" v-if="result.length > 0">
                <el-tab-pane
                  v-for="item in result"
                  :key="item.providerTopology.cluster"
                  :label="item.providerTopology.cluster"
                >
                  <div class="resource-container" v-for="(resource, index) in item.resources" :key="index">
                    <div class="resource-title">
                      <i class="el-icon-menu"></i>
                      {{ resource.type }}
                    </div>
                    <div
                      class="resource-item"
                      v-for="sample in resource.samples"
                      :key="sample.labels.name"
                      @click="toResultDetail(resource.type, sample.labels, item.providerTopology)"
                    >
                      <div class="name">
                        {{ sample.labels.name }}
                      </div>

                      <div class="value" v-if="['PersistentVolumeClaims', 'PersistentVolume'].includes(resource.type)">
                        {{ sample.value }}{{ resource.unit }}
                      </div>

                      <div class="time" v-else>
                        {{ moment(sample.value * 1000).format("YYYY-MM-DD HH:mm:ss ") }}
                      </div>

                      <div class="namespace">
                        {{ sample.labels.namespace }}
                      </div>
                    </div>
                  </div>

                  <result type="empty" v-if="item.resources.length < 1" />
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>

          <div slot="reference" @mousedown.stop>
            <el-input
              v-model="searchText"
              @keyup.enter.native="search"
              @focus="searchInputFocus"
              @input="searchInputEvent"
              placeholder="查找资源"
            >
              <el-button slot="append" type="primary" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </div>
        </el-popover>
      </div>
    </div>

    <div class="control-component-container">
      <div class="control-panel-title">
        <svg-icon icon-class="Cluster"></svg-icon>
        {{ $t("Cluster") }}
      </div>

      <div class="item-container">
        <div
          class="item"
          v-for="item in providers"
          :key="item.providerID"
          :class="{ ready: item.ready, 'not-ready': !item.ready, deny: item.status === 'Deny' }"
        >
          <div class="item-title">
            {{ item.cluster }}
          </div>

          <img :src="aws" v-if="item.kind == 'ProviderAWS'" />
          <img :src="aliyun" v-if="item.kind == 'ProviderALiCloud'" />
          <img :src="gcp" v-if="item.kind == 'ProviderGCP'" />
          <img :src="onpremise" v-if="item.kind == 'ProviderKubeconfig'" />
          <img :src="tencentcloud" v-if="item.kind == 'ProviderTencent'" />
          <img :src="huaweicloud" v-if="item.kind == 'ProviderHuawei'" />

          <div class="name-value">
            <span class="name">{{ $t("status") }}: </span>
            <div class="status success" v-if="item.ready">Ready</div>
            <div class="status danger" v-else>NotReady</div>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("region") }}: </span>
            <span class="value">{{ item.regionID }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("vendor") }}: </span>
            <span class="value">{{ item.kind }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("role") }}: </span>
            <span class="value">{{ item.roles.map(item => item.roleName).join("/") }}</span>
          </div>

          <div class="name-value">
            <span class="name">访问状态: </span>
            <div class="status error" v-if="item.status === 'Deny'">禁止访问</div>
            <div class="status success" v-else>允许访问</div>
          </div>
        </div>

        <div class="empty-text" v-if="isNull(providers) || providers.length < 1">
          暂无绑定的集群 ，请
          <a href="javascript:;" @click="$router.push('/admin/cluster/add')">[立即创建]</a>
        </div>
      </div>
    </div>

    <div class="control-component-container">
      <div class="control-panel-title">
        <svg-icon icon-class="history"></svg-icon>
        {{ $t("history") }}
      </div>

      <div class="item-container">
        <div class="item record" v-for="item in recordList" :key="item.fullPath">
          <div class="item-title">
            <a @click="toDetail(item.fullPath)"> {{ item.name }}</a>
          </div>

          <svg-icon :icon-class="item.type" class-name="type-icon"></svg-icon>

          <div class="name-value">
            <span class="name">{{ $t("type") }}: </span>
            <span class="value">{{ item.type }}</span>
          </div>

          <div class="name-value" v-if="item.namespace">
            <span class="name">{{ $t("Namespace") }}: </span>
            <span class="value">{{ item.namespace ? item.namespace : "-" }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("Cluster") }}: </span>
            <span class="value">{{ item.cluster }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("region") }}: </span>
            <span class="value">{{ item.region }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("vendor") }}: </span>
            <span class="value">{{ item.vendor }}</span>
          </div>
        </div>

        <div class="empty-text" v-if="recordList.length < 1">
          暂无最近访问的资源
        </div>
      </div>
    </div>

    <el-row :gutter="20">
      <el-col :span="18">
        <div class="common-container-style">
          <div class="control-panel-title">
            <svg-icon icon-class="tool"></svg-icon>
            {{ $t("KubeStarTools") }}
          </div>

          <el-row :gutter="20">
            <el-col :span="8">
              <div class="tool-container">
                <div>
                  <router-link to="/cluster-dashboard">
                    <div class="list-title">
                     {{ $t("tools.clusterMonitor") }}
                    </div>
                  </router-link>

                  <div class="desc">
                    {{ $t("tools.clusterMonitorDescription") }}
                  </div>
                </div>

                <img :src="clusterMonitorSVG" />
              </div>
            </el-col>

            <el-col :span="8">
              <div class="tool-container">
                <div>
                  <router-link to="/audit">
                    <div class="list-title">
                      {{ $t("tools.audit") }}
                    </div>
                  </router-link>

                  <div class="desc">
                    {{ $t("tools.auditDescription") }}
                  </div>
                </div>

                <img :src="auditSVG" />
              </div>
            </el-col>

            <el-col :span="8">
              <div class="tool-container">
                <div>
                  <router-link to="/add/import">
                    <div class="list-title">
                      {{ $t("tools.importYaml") }}
                    </div>
                  </router-link>
                  <div class="desc">
                    {{ $t("tools.importYamlDescription") }}
                  </div>
                </div>

                <img :src="importYAMLSVG" />
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="common-container-style">
          <div class="control-panel-title">
            <svg-icon icon-class="doc"></svg-icon>
            {{ $t("KubeStarDocs") }}
          </div>

          <div class="tool-container">
            <div class="docs-link">
              <a :href="'http://docs.kubestar.io/' + docsType" target="_blank">
                <i class="el-icon-link"></i>
                Docs Link
              </a>
            </div>

            <img :src="docsSVG" style="width: 130px; " />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import auditSVG from "@/assets/illustration/audit.svg";
import clusterMonitorSVG from "@/assets/illustration/cluster-monitor.svg";
import importYAMLSVG from "@/assets/illustration/import-yaml.svg";
import docsSVG from "@/assets/illustration/docs.svg";
import provider from "@/mixins/provider";
import Result from "@/components/Result.vue";
import { searchGlobal } from "api/monitor";
import { isNull } from "lodash";
import { getCookies } from "utils/cookies";

export default {
  mixins: [provider],

  components: {
    Result
  },

  data() {
    return {
      auditSVG,
      clusterMonitorSVG,
      importYAMLSVG,
      docsSVG,
      searchText: "",
      popoverVisible: false,
      searchLoading: true,
      result: []
    };
  },

  methods: {
    isNull,

    search() {
      this.result = [];

      if (this.searchText) {
        this.searchLoading = true;
        this.popoverVisible = true;

        searchGlobal(this.searchText).then(response => {
          if (response.code === 0) {
            this.searchLoading = false;
            this.result = response.data;
          }
        });
      }
    },

    searchInputFocus() {
      if (this.searchText && this.result.length > 0) {
        this.popoverVisible = true;
      }
    },

    searchInputEvent() {
      if (!this.searchText) {
        this.popoverVisible = false;
        this.result = [];
      }
    },

    toDetail(path) {
      this.$emit("routerGo");

      this.$router.push({
        path
      });
    },

    toResultDetail(type, label, provider) {
      this.searchText = "";
      this.popoverVisible = false;

      let { name, namespace } = label;
      let { cluster, region, vendor } = provider;

      let query = namespace
        ? { vendor: vendor, region: region, cluster: cluster, namespace: namespace }
        : { vendor: vendor, region: region, cluster: cluster };

      this.$emit("routerGo");

      this.$router.push({
        path: `/detail/${type}/${name}`,
        query
      });
    }
  },

  computed: {
    userName() {
      return this.$store.state.app.userName;
    },

    providers() {
      return this.$store.state.app.userInfo.providers;
    },

    recordList() {
      return this.$store.state.app.recordList;
    },

    docsType() {
      let lang = getCookies("language");
      let docsType = "en/";

      switch (lang) {
        case "en":
          docsType = "en/";
          break;

        case "ja":
          docsType = "en/";
          break;

        case "zh":
          docsType = "cn/";
          break;
      }

      return docsType;
    }
  },

  mounted() {
    document.addEventListener("mousedown", () => {
      this.popoverVisible = false;
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.control-panel-title {
  @include title(14px);
  margin-bottom: 20px;

  @include flex(flex-start);

  .svg-icon {
    width: 22px !important;
    height: 22px !important;
  }
}

.kubestar-title {
  @extend .control-panel-title;
  @include title(18px);
}

.search-contaier {
  background-image: url("../../assets/search-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;

  .search-title {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
  }

  .search-input {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .el-input-group {
      width: 800px;
    }

    /deep/ .el-input__inner {
      &:focus {
        border-color: transparent;
      }
    }
  }
}

.common-container-style {
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: rgb(250, 251, 252);
}

.control-component-container {
  overflow-x: auto;

  @extend .common-container-style;

  .item-container {
    display: inline-flex;
  }

  .item {
    position: relative;

    img,
    .type-icon {
      position: absolute;
      opacity: 0.4;
      right: 0;
      bottom: 10px;
    }

    img {
      width: 80px;
    }

    vertical-align: top;
    margin-right: 30px;
    border-left: 4px solid #99ccff;
    background: #fff;
    padding: 16px 10px 0;

    width: 240px;
    box-sizing: border-box;
    border-radius: 8px;

    &.ready {
      border-left-color: #99ccff;
    }

    &.not-ready {
      border-left-color: #ff6666;
    }

    &.deny {
      background-color: #f4f4f5;
      border-left-color: #e9e9eb;
      cursor: not-allowed;
    }

    // &.record {
    //   border-left-color: #66ccff;
    // }

    box-shadow: $common-shadow;
  }

  .item-title {
    @include title(14px);
    margin-bottom: 8px;

    a {
      color: $color-primary;
    }
  }
}

.docs-link {
  & > a {
    display: block;
    font-size: 12px;
    line-height: 26px;
    color: $color-main;
    font-weight: 700;

    i {
      color: $color-primary;
      font-size: 18px;
    }
  }
}

.tool-container {
  @extend .common-container-style;
  margin-bottom: 0;
  background-color: #fff;
  @include flex($wrap: nowrap);

  .list-title {
    @include title(14px);
    display: inline-block;
    margin-bottom: 4px;
  }

  img {
    height: 90px;
  }
}

.search-popover-container {
  min-height: 60px;
  max-height: 500px;
  overflow-y: auto;

  & > div {
    min-height: 50px;
  }

  .title {
    color: $color-sub;
  }
}

.resource-container {
  margin-bottom: 10px;

  .resource-title {
    @include title(16px);
  }

  .resource-item {
    cursor: pointer;
    line-height: 30px;
    @include flex(flex-start, center, nowrap);
    font-size: 12px;
    padding: 0 10px;

    &:hover {
      background: #f1f5f8;
    }

    .name {
      flex: 1;
    }

    .namespace,
    .time,
    .value {
      width: 160px;
      @include text-overflow();
    }
  }
}
</style>
