import Vue from "vue";
import VueI18n from "vue-i18n";
import zh from "./zh";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";

Vue.use(VueI18n);

const messages = {
  zh: {
    ...elementZhLocale,
    ...zh
  }
};

const i18n = new VueI18n({
  locale: getLanguage(),
  messages
});

export function getLanguage() {
  return "zh";
}

export default i18n;
