import http from "../http";

export const configMapList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/configmaps`
  });
};

export const configMapListByLocation = (data, params) => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/configmaps`,
    params
  });
};

export const configMapDetail = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/configmaps/${data.configmap}`
  });
};

export const configMapSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/configmaps`,
    data,
    headers
  });
};

export const configMapUpdate = (data, headers) => {
  return http({
    method: "put",
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/configmaps/${data.metadata.name}`,
    data,
    headers
  });
};

export const configMapDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/configmaps/${data.name}`
  });
};
