<template>
  <el-dropdown trigger="click" @command="handleSetLanguage" size="small">
    <span class="el-dropdown-link">
      <svg-icon icon-class="language" class-name="handle-icon"></svg-icon>
    </span>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language === 'en'" command="en">
        English
      </el-dropdown-item>

      <el-dropdown-item :disabled="language === 'zh'" command="zh">
        中文
      </el-dropdown-item>

      <el-dropdown-item :disabled="language === 'ja'" command="ja">
        日本語
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "LanguageSelect",
  computed: {
    language() {
      return this.$store.state.app.language;
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("app/setLanguage", lang);

      window.location.reload();

      //const { fullPath } = this.$route;

      // this.$nextTick(() => {
      //   this.$router.replace({
      //     path: "/redirect" + fullPath
      //   });
      // });
    }
  }
};
</script>
