import router from "./index.js";
import store from "../store/index";
import { getCookies } from "@/utils/cookies";
import { isEmpty } from "lodash";
import { Loading } from "element-ui";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import sassConfig from "@/utils/sassconfig";

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  if (to.name === "Detail") await store.dispatch("app/setRecordList", to);

  const hasToken = getCookies("token");

  if (hasToken) {
    // 用于解决浏览器开多个Tab页的临时解决方案
    // 问题本质原因是因为token发生改变时，X-KubeStar-Token应该被清除
    if (to.name === "Home") await store.dispatch("app/getAppInfo");

    if (store.state.app.organizationList.length < 1) {
      await store.dispatch("app/getOrganizationList");
      await store.dispatch("app/getCurrentOrganizationInfo");
    }

    if (isEmpty(store.state.app.userInfo)) {
      try {
        const loading = Loading.service({
          lock: true,
          text: "Loading...",
          spinner: "el-icon-loading",
          fullscreen: true
        });

        const { role } = await store.dispatch("app/getUserInfo");
        const accessRoutes = await store.dispatch("permission/generateRoutes", role);
        router.addRoutes(accessRoutes);
        loading.close();

        // hack method to ensure that addRoutes is complete
        // set the replace: true, so the navigation will not leave a history record
        next({ ...to, replace: true });
      } catch (error) {
        NProgress.done();
      }
    } else {
      next();
    }
  } else {
    NProgress.done();
    if (sassConfig.marshotspotUrl) {
      location.href = sassConfig.marshotspotUrl;
    } else {
      location.href =
      location.hostname.indexOf(".mc.cmcloud.org") > -1
        ? "http://test.mc.cmcloud.org/"
        : (location.hostname.indexOf('.marshotspot.com') > -1 ? "https://console.marshotspot.com/" : "https://console.polymericcloud.com/");
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
