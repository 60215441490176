<template>
  <div>
    <el-input v-model="data" :placeholder="$t('imageSelect')" @focus="dialogShow" readonly>
      <template slot="prepend">
        <i class="el-icon-document-copy" style="cursor: pointer;" v-if="data" v-clipboard:copy="data"></i>
      </template>
    </el-input>

    <el-dialog
      :title="$t('imageSelect')"
      :visible.sync="dialogVisible"
      append-to-body
      top="30px"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form size="small" label-position="top" v-loading="containerLoading">
        <el-form-item>
          <el-radio-group v-model="imageSelectType">
            <el-radio-button label="select">选择镜像</el-radio-button>
            <el-radio-button label="input">手动填写</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <div v-if="imageSelectType == 'select'">
          <el-form-item :label="$t('registry')">
            <el-select
              v-model="registryId"
              style="width: 100%"
              filterable
              @change="registryChange"
              popper-class="image-select"
            >
              <el-option v-for="item in registries" :key="item.uuid" :label="item.host" :value="item.uuid">
                <div class="image-list">
                  <div class="content">
                    <div class="title">[{{ item.name }}] {{ item.host }}</div>
                    <div class="desc">{{ item.kind }}</div>
                  </div>
                </div>
              </el-option>

              <el-option label="docker.io" value="9999">
                <div class="image-list">
                  <div class="content">
                    <div class="title">[DockerHub]dockerhub.io</div>
                    <div class="desc">
                      DockerHub
                    </div>
                  </div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('imageName')">
            <div v-if="registryId === '9999'">
              <el-select
                v-model="image"
                :loading="imageListLoading"
                filterable
                remote
                @change="imageChange"
                :remote-method="remoteMethod"
                v-if="registryId === '9999'"
                popper-class="image-select"
                style="width: 100%"
              >
                <el-option v-for="item in imageList" :key="item.name" :label="item.slug" :value="item.slug">
                  <div class="image-list">
                    <img :src="item.logo_url.small || item.logo_url.large" :onerror="defaultImage" />
                    <div class="content">
                      <div class="title">{{ item.name }}</div>
                      <div class="desc">{{ item.short_description }}</div>
                    </div>
                  </div>
                </el-option>
              </el-select>
            </div>

            <div v-else>
              <el-input v-model="image" @blur="imageChange" v-if="allowCreate"></el-input>

              <el-select
                v-model="image"
                style="width: 100%"
                :loading="imageListLoading"
                filterable
                v-else
                @change="imageChange"
                popper-class="image-select"
              >
                <el-option
                  v-for="item in imageList"
                  :key="item.repositoryName"
                  :label="item.repositoryName"
                  :value="item.repositoryName"
                >
                  <div class="image-list">
                    <svg-icon icon-class="Image"></svg-icon>

                    <div class="content">
                      <div class="title">{{ item.repositoryName }}</div>
                      <div class="desc">
                        {{ item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "-" }}
                      </div>
                    </div>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item :label="$t('imageTag')">
            <el-select
              v-model="imageTag"
              style="width: 100%"
              :loading="tagListLoading"
              filterable
              popper-class="image-select"
            >
              <el-option
                v-for="item in tagList"
                :key="item.imageTags[0]"
                :label="item.imageTags[0]"
                :value="item.imageTags[0]"
              >
                <div class="image-list">
                  <svg-icon icon-class="tag"></svg-icon>

                  <div class="content">
                    <div class="title">{{ item.imageTags[0] }}</div>
                    <div class="desc">
                      {{ item.imagePushedAt ? moment(item.imagePushedAt).format("YYYY-MM-DD HH:mm:ss") : "-" }}
                    </div>
                  </div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div v-else>
          <el-form-item :label="$t('image')">
            <el-input v-model="inputData"></el-input>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer">
        <el-button type="primary" size="small" @click="submit">{{ $t("handle.submit") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { imageSplit, getRegistries, imageList, imageTagList, dockerHubImageList } from "api/registry";

export default {
  name: "ImageSelect",

  props: {
    data: {
      type: String
    },

    locationList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  data() {
    return {
      dialogVisible: false,
      type: "",
      registryId: "",
      host: "",
      image: "",
      imageTag: "",
      registries: [],
      imageList: [],
      tagList: [],
      imageListLoading: false,
      tagListLoading: false,
      defaultImage: 'this.src="' + require("../assets/image.png") + '"',
      allowCreate: false,
      containerLoading: true,
      inputData: "",
      currentProviderID: "",
      imageSelectType: "select" // select | input
    };
  },

  methods: {
    dialogShow() {
      this.dialogVisible = true;

      this.storeLocationList.forEach(item => {
        if (
          item.value == this.locationList[0] &&
          item.children[0].value == this.locationList[1] &&
          item.children[0].children[0].value == this.locationList[2]
        ) {
          this.currentProviderID = item.uuid;
        }
      });

      if (this.data) {
        this.imageSplit();
      } else {
        this.getRegistries();
        this.containerLoading = false;
        this.imageSelectType = "select";
      }
    },

    getRegistries() {
      getRegistries(this.currentProviderID).then(response => {
        if (response.code === 0) {
          this.registries = response.data;
        }
      });
    },

    registryChange() {
      this.allowCreate = false;
      this.image = "";
      this.imageTag = "";
      this.imageList = [];
      this.tagList = [];

      if (this.registryId == "9999") {
        this.host = "docker.io";
        this.remoteMethod("");
      } else {
        for (let item of this.registries) {
          if (item.uuid == this.registryId) {
            this.host = item.host;
          }
        }

        this.host === "docker.io" ? (this.allowCreate = true) : this.getImageList();
      }
    },

    getImageList() {
      this.imageListLoading = true;

      imageList(this.registryId).then(response => {
        this.imageListLoading = false;

        if (response.code === 0) {
          this.imageList = response.data.items;
        }
      });
    },

    remoteMethod(query) {
      this.imageListLoading = true;
      dockerHubImageList({
        page: 1,
        pageSize: 50,
        search: query
      }).then(response => {
        this.imageListLoading = false;

        if (response.code === 0) {
          this.imageList = response.data.items;
        }
      });
    },

    imageChange() {
      this.imageTag = "";
      this.tagList = [];
      if (this.image) this.getTagList();
    },

    getTagList() {
      this.tagListLoading = true;
      let params = null;

      this.registryId == "9999"
        ? (params = [this.registryId, this.image, { "X-KubeStar-Registry": "Dockerhub" }])
        : (params = [this.registryId, this.image]);

      imageTagList(...params).then(response => {
        this.tagListLoading = false;
        if (response.code === 0) {
          this.tagList = response.data.items;
        }
      });
    },

    selectExistRegistry() {
      // this.type = "exist";
      this.imageSelectType = "select";
      this.registryId = null;
      this.host = "";
      this.image = "";
      this.imageTag = "";
      this.imageList = [];
      this.tagList = [];

      this.getRegistries();
    },

    imageSplit() {
      this.containerLoading = true;
      imageSplit(this.currentProviderID, { image: this.data }).then(response => {
        if (response.code === 0) {
          this.registryId = response.data.registryId;
          this.allowCreate = false;

          this.getRegistries();

          if (response.data.registryId) {
            this.host = response.data.host;
            this.image = response.data.subPath
              ? `${response.data.subPath}/${response.data.image}`
              : `${response.data.image}`;
            this.imageTag = response.data.tag;

            this.imageSelectType = "select";

            if (this.registryId == "9999") {
              this.remoteMethod(this.image);
            } else if (this.host === "docker.io") {
              this.allowCreate = true;
            } else {
              this.getImageList();
            }

            this.getTagList();
          } else {
            this.imageSelectType = "input";
            this.inputData = this.data;
          }

          this.containerLoading = false;
        }
      });
    },

    submit() {
      if (this.imageSelectType === "select") {
        if (this.image && this.imageTag && this.host)
          this.$emit("update:data", `${this.host}/${this.image}:${this.imageTag}`);
      } else {
        if (this.inputData) this.$emit("update:data", this.inputData);
      }

      this.dialogVisible = false;
    }
  },

  computed: {
    storeLocationList() {
      return this.$store.state.app.locationList;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.image-list {
  @include resource-card();

  margin-bottom: 0;
  margin-top: 10px;

  img {
    width: 30px;
    height: 30px;
  }
}

.image-select {
  .el-select-dropdown__item {
    height: 76px;
    line-height: 1;
  }
}
</style>
