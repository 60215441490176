<template>
  <div class="labels-container">
    <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom" v-if="editable">
      <i class="el-icon-edit-outline" @click="editLabels"></i>
    </el-tooltip>

    <div class="label" v-for="(value, name) in labels" :key="name">
      <span class="label-name">
        {{ name }}
      </span>

      <el-popover placement="top-start" width="400" trigger="click" v-if="isJSON(value)">
        <editor
          :value="JSON.stringify(JSON.parse(labels[name]), null, 2)"
          @init="jsonEditorInit"
          lang="json"
          theme="cobalt"
          width="100%"
          height="200"
        >
        </editor>
        <a href="javascript:;" slot="reference">点击查看</a>
      </el-popover>

      <span v-else>{{ value ? value : "-" }}</span>
    </div>

    <div v-if="isEmpty(labels)">-</div>

    <el-dialog :title="$t('handle.edit')" :visible.sync="dialogVisible" top="30px">
      <el-alert
        title="修改 Annotation/Label 会触发Deployment, Statefulset, Daemonset, Cronjob, Pod 重启，不会影响Node状态"
        type="warning"
        show-icon
        style="margin-bottom: 10px;"
      >
      </el-alert>

      <edit-labels :data="labels" ref="editLabelForm" v-if="dialogVisible" :edit-type="editType" />

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="loading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty, transform, cloneDeep, isObject } from "lodash";
import { nodeUpdate } from "api/cluster";
import { batchAnnotations } from "api/label";

export default {
  name: "Labels",

  components: {
    editor: require("vue2-ace-editor")
  },

  props: {
    labels: {
      type: Object
    },

    editable: {
      type: Boolean,
      default: false
    },

    editType: {
      type: String
    }
  },

  data() {
    return {
      dialogVisible: false,
      loading: false
    };
  },

  methods: {
    isEmpty,
    isObject,

    isJSON(str) {
      if (typeof str == "string") {
        try {
          let obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch {
          return false;
        }
      }

      return false;
    },

    editLabels() {
      this.dialogVisible = true;
    },

    jsonEditorInit: function() {
      require("brace/ext/searchbox");
      require("brace/mode/json");
      require("brace/theme/cobalt");
    },

    submit() {
      this.$refs["editLabelForm"].$refs["form"].validate(valid => {
        if (valid) {
          let annotations = transform(
            this.$refs["editLabelForm"].form.labels,
            function(result, item) {
              result[item.key] = item.value;
            },
            {}
          );

          let data = {
            name: this.name,
            namespace: this.namespace,
            type: this.type,
            annotations
          };

          this.loading = true;

          batchAnnotations(
            {
              vendor: this.vendor,
              region: this.region,
              cluster: this.cluster
            },
            data
          ).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.dialogVisible = false;
              this.$emit("update");
            }
          });

          // let action = null;
          // let params = {};

          // switch (this.type) {
          //   case "Node":
          //     action = nodeUpdate;
          //     params = { ...this.$route.query, node: this.$route.params.name };
          //     break;
          // }

          // let data = cloneDeep(this.data);
          // data.metadata.labels = labels;

          // this.loading = true;

          // action(params, data).then(response => {
          //   this.loading = false;
          //   if (response.code === 0) {
          //     this.dialogVisible = false;
          //     this.$emit("update");
          //   }
          // });
        }
      });
    }
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    name() {
      return this.$route.params.name;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.labels-container {
  position: relative;

  .el-icon-edit-outline {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -20px;
    @include title(20px);
  }
}
</style>
