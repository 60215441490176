<template>
  <div>
    <span class="el-dropdown-link">
      <svg-icon icon-class="company" class-name="handle-icon"></svg-icon>
      <span class="name organization-name"> {{ organizationCNName }} ({{ organizationName }}) </span>
      <!-- <i class="el-icon-caret-bottom"></i> -->
    </span>
    <!-- <el-dropdown class="organization-container" @command="change" trigger="click" size="small">
      <span class="el-dropdown-link">
        <svg-icon icon-class="company" class-name="handle-icon"></svg-icon>
        <span class="name"> {{ organizationCNName }} ({{ organizationName }}) </span>
        <i class="el-icon-caret-bottom"></i>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="item in organizationList" :key="item.uuid" :command="item">
          {{ item.cnName }} ({{ item.name }})
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="800px"
    >
      当前用户未关联任何租户
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import router, { resetRouter } from "@/router/index.js";

export default {
  data() {
    return {
      dialogVisible: false
    };
  },

  computed: {
    organizationList() {
      return this.$store.state.app.organizationList;
    },

    organizationName() {
      return this.$store.state.app.organizationName;
    },

    organizationCNName() {
      return this.$store.state.app.organizationCNName;
    }
  },

  methods: {
    afterSubmit() {
      this.dialogVisible = false;
      this.resetRouter();
    },

    change(item) {
      const loading = Loading.service({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        fullscreen: true
      });

      setTimeout(async () => {
        this.$store.commit("app/SET_ORGANIZATION", item);
        this.$store.commit("app/CLEAR_RECORDLIST");
        await this.resetRouter();
        loading.close();
      }, 300);
    },

    async resetRouter() {
      resetRouter();

      await this.$store.dispatch("app/getOrganizationList");
      let { role } = await this.$store.dispatch("app/getUserInfo");

      const accessRoutes = await this.$store.dispatch("permission/generateRoutes", role);
      router.addRoutes(accessRoutes);

      const { fullPath } = this.$route;

      // 重新获取列表
      this.$nextTick(() => {
        this.$router.replace({
          path: "/redirect" + fullPath
        });
      });
    }
  },

  mounted() {
    if (this.organizationList.length < 1) {
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.organization-container {
  font-size: 12px;

  .el-dropdown-link {
    @include flex(flex-start);
    color: $color-sub;
    padding: 0 10px;
    color: #fff;

    .name {
      max-width: 150px;
      @include text-overflow();
    }

    // .el-icon-caret-bottom {
    //   position: absolute;
    //   top: 50%;
    //   right: 0;
    //   transform: translateY(-50%);
    // }
  }
}

.organization-name {
  max-width: 150px;
  @include text-overflow();
}
</style>
