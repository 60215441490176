<template>
  <header class="header clearfix">
    <div class="header-nav-container">
      <el-popover
        placement="bottom"
        width="1000"
        trigger="click"
        class="header-nav"
        popper-class="header-nav-popover"
        :visible-arrow="false"
        v-model="popoverVisible"
      >
        <user-nav />

        <div slot="reference">
          <svg-icon icon-class="menu" class-name="handle-icon"></svg-icon>
        </div>
      </el-popover>
    </div>

    <div class="logo">
      <img :src="logo" />
      <a href="#/">
        {{ $t("KubeStar") }}
      </a>
    </div>

    <div class="right-menu">
      <organization-list class="right-menu-item" />

      <div class="right-menu-item back-to-juyun">
        <el-tooltip effect="dark" content="返回多云管理平台" placement="bottom">
          <svg-icon icon-class="juyun" class-name="handle-icon" @click.native="jumpToJuyun"></svg-icon>
        </el-tooltip>
      </div>

      <el-dropdown class="right-menu-item" trigger="click" size="small">
        <span class="el-dropdown-link">
          <svg-icon icon-class="user" class-name="handle-icon"></svg-icon>
          <span>{{ userName }}</span>
          <i class="el-icon-caret-bottom"></i>
        </span>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div style="font-size: 12px" @click="logout">{{ $t("logout") }}</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
// import AboutUs from "./AboutUs";
import logo from "@/assets/k8s-b.svg";
// import UserSetting from "@/pages/User/Update";
import UserNav from "./UserNav";
import OrganizationList from "./OrganizationList";
// import AlarmGlobal from "@/components/AlarmGlobal";
import { isArray } from "lodash";
import { Loading } from "element-ui";
import { logout } from "api/user";
import { removeCookies } from "@/utils/cookies";

export default {
  components: {
    // UserSetting,
    // AlarmGlobal,
    // AboutUs,
    UserNav,
    OrganizationList,
  },

  data() {
    return {
      logo,
      popoverVisible: false,
    };
  },

  methods: {
    logout() {
      const loading = Loading.service({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        fullscreen: true,
      });

      logout({}).then(() => {
        this.$store.dispatch("app/logout").then(() => {
          // this.$router.push({ path: "/login" });
          loading.close();
          this.jumpToJuyun();
        });
      });
    },

    jumpToJuyun() {
      if (this.$sass.marshotspotUrl) {
        location.href = this.$sass.marshotspotUrl
      } else {
        location.href =
        location.hostname.indexOf(".mc.cmcloud.org") > -1
          ? "http://test.mc.cmcloud.org/"
          : (location.hostname.indexOf('.marshotspot.com') > -1 ? "https://console.marshotspot.com/" : "https://console.polymericcloud.com/");
      }
      removeCookies("X-KubeStar-Token");
      removeCookies("X-KubeStar-UserName");
      removeCookies("X-KubeStar-UserID");
      removeCookies("organization");
      removeCookies("organizationName");
      removeCookies("organizationCNName");
    },
  },

  computed: {
    userName() {
      return this.$store.state.app.userName;
    },
  },

  watch: {
    $route() {
      this.popoverVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

$header-hover-color: #000c17;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  background-color: $header-bg-color;
  box-shadow: $common-shadow;
  overflow: hidden;
  z-index: 1999;
  text-align: center;
}

.header-nav-container {
  float: left;
}

.right-menu {
  float: right;
  line-height: 50px;
  height: 100%;
}

.right-menu-item {
  float: left;
  height: 100%;
  cursor: pointer;
  color: $color-sub;
  transition: 0.3s all ease;
  vertical-align: middle;
  font-size: 12px;
  position: relative;

  /deep/.el-badge {
    vertical-align: text-bottom;
  }

  /deep/.svg-icon {
    vertical-align: middle !important;
  }

  padding: 0 6px;

  .el-dropdown-link {
    @include flex(flex-start);
    height: 100%;
    color: $color-sub;
    padding: 0 6px;
    transition: 0.2s all ease;
    color: #fff;

    font-size: 14px;
  }

  &:hover {
    background-color: #000;
  }
}

.back-to-juyun {
  width: 30px;

  .svg-icon.handle-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.logo {
  height: 50px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include flex(flex-start);

  a {
    color: #fff;
    font-size: 16px;
  }

  img {
    height: 24px;
    width: 24px;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.header-nav {
  @extend .right-menu-item;
}

.header-nav-popover {
  max-height: calc(100vh - 40px);
  overflow-y: scroll;
  margin-bottom: 20px;

  &.el-popover {
    background: $header-bg-color;
    border-color: transparent;
    border-top-color: #545b64;
    padding: 0;
  }

  &.el-popper[x-placement^="bottom"] {
    margin-top: 0;
  }
}
</style>
