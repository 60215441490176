export default {
  KubeStar: "容器云管理平台",
  history: "您的最近访问记录",
  historyEmpty: "暂无最近访问记录",
  mainNavTitle: "服务",
  knowKubeStar: "了解KubeStar",
  Dashboard: "Dashboard",
  Event: "事件",
  aboutUs:
    "KubeStar是一款企业级分布式容器管理平台，用于云原生应用和跨云基础设施管理，KubeStar可以跨环境、跨集群、跨区域和跨云管理云原生应用和基础设施",

  KubeStarControlPanel: "容器云控制台",
  KubeStarDocs: "文档",

  userName: "用户名",
  email: "邮箱",
  isAdmin: "管理员用户",
  password: "密码",
  confirmPassword: "确认密码",
  personalSetting: "个人设置",
  changePassword: "修改密码",

  vendor: "云服务商",
  region: "地区",
  path: "路径",
  port: "端口",
  protocol: "协议",
  createTime: "创建时间",
  tag: "标签",
  detail: "详情",
  annotations: "注解",
  resourceRelated: "关联资源",
  host: "域名",

  location: "位置",
  image: "镜像",
  cpu: "CPU",
  memory: "内存",
  name: "名称",
  version: "版本",
  currentVersion: "当前版本",
  basicConf: "基本信息",
  reason: "原因",
  message: "消息",
  time: "时间",
  status: "状态",
  role: "角色",
  type: "类型",
  address: "访问地址",
  endpoints: "实例端点",

  effect: "访问状态",
  effectAllow: "允许访问",
  effectDeny: "禁止访问",

  condition: "状态",
  description: "描述"
};
