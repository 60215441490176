import axios from "axios";
import store from "@/store/index";
import i18n from "@/lang";
import { Notification, MessageBox } from "element-ui";
import { debounce } from "lodash";
import { getCookies } from "@/utils/cookies";
import sassConfig from "@/utils/sassconfig"

// const domainCode = getCookies("domainCode");
const Token = getCookies("token");

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
  // baseURL: domainCode || process.env.VUE_APP_API_URL
});

http.defaults.headers.post["Content-Type"] = "application/json";

// 添加请求拦截器
http.interceptors.request.use(
  config => {
    if (store.state.app.token) {
      config.headers["X-KubeStar-Token"] = store.state.app.token;
      config.headers["X-KubeStar-UserID"] = store.state.app.userId;
      config.headers["X-KubeStar-UserName"] = store.state.app.userName;
    }

    if (store.state.app.organization) {
      config.headers["X-KubeStar-Organization"] = store.state.app.organization;
    }

    if (Token) {
      config.headers["Authorization"] = "JWT " + Token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    config.headers["X-KubeStar-ThirdpartyIAMKind"] = "IAMKindJUYUN";

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let alert = debounce(() => {
  MessageBox.alert(i18n.t("tokenError"), i18n.t("prompt"), {
    confirmButtonText: i18n.t("reLogin"),
    callback: () => {
      store.dispatch("app/logout").then(() => {
        if (sassConfig.marmarshotspotUrl) {
          location.href = sassConfig.marmarshotspotUrl;
        } else {
          location.href =
          location.hostname.indexOf(".mc.cmcloud.org") > -1
            ? "http://test.mc.cmcloud.org/"
            : (location.hostname.indexOf('.marshotspot.com') > -1 ? "https://console.marshotspot.com/" : "https://console.polymericcloud.com/");
        }
      });
    }
  });
}, 2000);

http.interceptors.response.use(
  response => {
    if (response.data.code === 9998) {
      alert();
      return Promise.reject(new Error("Token Error"));
    }

    let whiteList = ["/apidocs.json", "/monitor.kubestar.io/notification/alert/metrics"];

    if (response.data.code !== 0 && !whiteList.includes(response.config.url)) {
      Notification.error({
        title: "Error",
        message: `
        <div style="width: 100%; word-wrap: break-word;">
        <div class="title" style="font-size: 14px;">
        ${response.config.url}<br />
        </div>
        <div class="desc">
        ${responseMessageTrans(response.data)}
        </div>
        </div>
        `,
        dangerouslyUseHTMLString: true,
        duration: 10000
      });
    }

    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

function responseMessageTrans(data) {
  let { code, message } = data;
  let resultMessage = "";

  switch (code) {
    case 9997:
      resultMessage = "无操作权限，请联系管理员";
      break;

    default:
      resultMessage = message;
  }

  return resultMessage;
}

export default http;
