import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";

import "@/icons";
import "@/router/permission.js";
import "@/globalComponents";

import Steps from "ant-design-vue/lib/steps";
import "ant-design-vue/lib/steps/style/css";
Vue.use(Steps);

import Empty from "ant-design-vue/lib/empty";
import "ant-design-vue/lib/empty/style/css";
Vue.use(Empty);

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/index.scss";

import "splitpanes/dist/splitpanes.css";

import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import Progress from "vue-multiple-progress";
Vue.use(Progress);

import VueContentPlaceholders from "vue-content-placeholders";
Vue.use(VueContentPlaceholders);

import i18n from "./lang/index";

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
});

import moment from "moment";
Vue.prototype.moment = moment;
Vue.config.productionTip = false;

import sassConfig from "@/utils/sassconfig";
Vue.prototype.$sass = sassConfig;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
